import type { RouteRecordRaw } from 'vue-router'

const auditSystemAdminRoute: Array<RouteRecordRaw> = [
  {
    path: '/system-admin/audit',
    name: 'system-admin-audit',
    component: () => import('@/views/sys_admin/audit/AuditList.vue'),
    meta: {
      pageTitle: 'audit',
      breadcrumbs: ['systemAdmin', 'audit'],
    },
  },
]

export default auditSystemAdminRoute
