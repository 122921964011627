import type { RouteRecordRaw } from "vue-router";

const institutionConfigurationSystemAdminRoute: Array<RouteRecordRaw> = [
  {
    path: "/system-admin/configuration",
    name: "system-admin-institution-configuration",
    component: () => import("@/views/sys_admin/configuration/ConfigurationList.vue"),
    meta: {
      pageTitle: "configuration",
      breadcrumbs: ['systemAdmin', 'institution', 'configuration'],
    },
  },
];

export default institutionConfigurationSystemAdminRoute;
