import type { RouteRecordRaw } from "vue-router";

const institutionSystemAdminRoute: Array<RouteRecordRaw> = [
  {
    path: "/system-admin/institution",
    name: "system-admin-institution",
    component: () => import("@/views/sys_admin/institution/InstitutionList.vue"),
    meta: {
      pageTitle: "institution",
      breadcrumbs: ['systemAdmin', 'institution'],
    },
  },
  {
    path: "/system-admin/institution/detail",
    name: "system-admin-institution-detail",
    component: () => import("@/views/sys_admin/institution/InstitutionDetail.vue"),
    meta: {
      pageTitle: "institution",
      breadcrumbs: ['systemAdmin', 'institution', 'detail'],
    },
  },
  {
    path: "/system-admin/institution/create",
    name: "system-admin-institution-create",
    component: () => import("@/views/sys_admin/institution/InstitutionCreate.vue"),
    meta: {
      pageTitle: "institution",
      breadcrumbs: ['systemAdmin', 'institution', 'create'],
    },
  },
];

export default institutionSystemAdminRoute;
