import { defineStore } from 'pinia'

import type { IAdmissionColorData } from '@/core/types/color'
import { computed } from 'vue'
import { useConfigRegisterStore } from '@/stores/register'

export const useInstitutionColorProfileStore = defineStore('institution_profile', {
  state: () =>
    ({
      primary_color: '',
    } as IAdmissionColorData),
  persist: true,
  getters: {},
  actions: {
    async getInstitutionConfigCommon() {
      try {
        const configRegisterStore = useConfigRegisterStore()
        this.primary_color = computed(() => configRegisterStore.loadFromLocalStorageByKey('primary_color'))
      } catch (e: any) {
        throw new Error(e)
      }
    },
  },
})
